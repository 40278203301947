/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "targetId",
    "invoiceComponentId",
    "primaryButton"
  ]

  connect() {
    console.log("Connected Delete Controller");
  }

  processDelete(e) {
    e.preventDefault()
    var btn = this.primaryButtonTarget;
    var availableText = this.availableTextTarget;

    // This handles case in testing where there is no CSRF
    var csrfNode = document.querySelector("meta[name='csrf-token']");
    var csrfToken = ""
    if (csrfNode) {
      csrfToken = csrfNode.content;
    } else {
      ""
    }

    var target = this.targetIdTarget.dataset["value"];

    var invoiceComponentIds = [];
    this.invoiceComponentIdTargets.forEach(function (obj) {
      invoiceComponentIds.push(obj.dataset["value"])
    });

    let data = {
      target_id: target,
      invoice_components_id: invoiceComponentIds
    };

    fetch(this.data.get("url"), {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      method: "POST",
      body: JSON.stringify(data)
    })
    .then(r => r.text())
    .then(html => { 
      Turbo.renderStreamMessage(html)

      const event = new CustomEvent("showModal", { })
      window.dispatchEvent(event)
      console.log("Dispatched showModal event")
    })
    .catch(error => console.error('Error:', error));
  }
}
