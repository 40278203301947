// app/javascript/controllers/turbo_modal_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modalContent"]

  show() {
    console.log("Show Modal")
    this.element.classList.add("in")
    this.element.classList.add("visible")
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.modalContentTarget.innerHTML = ""

    this.element.classList.remove("in")
    this.element.classList.remove("visible")
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  closeBackground(e) {
    if (e && this.element.contains(e.target) && (e.target != this.element)) {
      return
    }
    this.hideModal()
  }
}